import { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink as RouterLink, useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import { handleLabelKEY } from "../../../common/commonFunctions";
import { getImageUrl } from "../../../common/handleAmazonS3Image";
import VideoModalComponent from "../../../components/dashboard/videoModalComponent";
import { IRootReducer } from "../../../store/root-reducer";

const arrow = getImageUrl("bstamp-images/Arrow.svg");
const EdexaWhitelogo = getImageUrl("bstamp-images/edexa-white-logo.svg");
const QrcodeImage = getImageUrl("bstamp-images/qrcode-image.png");
const BannerImage = getImageUrl("bstamp-images/banner-image.png");

const BannerSectionComponent = () => {
  const history = useHistory();
  const [videoPlay, setVideoPlay] = useState(false);

  const selected_Language = useSelector((state: IRootReducer) => state.labelsReducer.lang_);
  const adminlabelsFromReducer = useSelector((state: IRootReducer) => state.labelsReducer.labels);

  const searchToogle = () => {
    history.push({
      pathname: "/search",
      state: { key: "validate" }
    });
  };

  return (
    <section className="banner-section position-relative d-flex flex-wrap align-items-center justify-content-center">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5 text-lg-start text-center mb-lg-0 mb-4 order-lg-1 order-2">
            <div className="banner-content position-relative z-index1">
              <h1 className="mb-3">
                <span className="d-block">bStamp</span>
                {handleLabelKEY(selected_Language === "English" ? adminlabelsFromReducer?.EN?.with_indelible_proof : adminlabelsFromReducer?.DE?.with_indelible_proof, "with indelible proof")}
              </h1>
              <p className="mb-4">
                {handleLabelKEY(
                  selected_Language === "English"
                    ? adminlabelsFromReducer?.EN?.bring_attribution_accountability_and_auditability_to_your_business_processes_with_edexa_business_blockchains
                    : adminlabelsFromReducer?.DE?.bring_attribution_accountability_and_auditability_to_your_business_processes_with_edexa_business_blockchains,
                  "Bring attribution, accountability and auditability to your business processes with edeXa Business blockchain"
                )}
              </p>
              <div className="validateButtonSection">
                {/* @ts-ignore */}
                <Button color="link" className="banner-hash-button bstamp-blue-button text-capitalize align-self-center validateButton" onClick={() => searchToogle()}>
                  {handleLabelKEY(selected_Language === "English" ? adminlabelsFromReducer?.EN?.validate : adminlabelsFromReducer?.DE?.validate, "Validate")}
                </Button>
                <img className="arrow" src={arrow} alt="arrow" />
                <div className="videoPlay">
                  <span color="link" className="videoCircle" onClick={() => setVideoPlay(true)}>
                    <i className="fas fa-play"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-7 mb-lg-0 mb-4 order-lg-2 order-1">
            <div className="image-wrapper position-relative z-index1">
              <img src={BannerImage} alt="banner" className="banner-image" />
              <RouterLink to="/" className="qrcode-image position-absolute z-index1">
                <img src={QrcodeImage} alt="Qr code" />
              </RouterLink>
            </div>
          </div>
        </div>
        <div className="poweredBySection">
          <div className="poweredby z-index1  my-lg-0 my-2">
            Powered by <img src={EdexaWhitelogo} alt="edexaLogo" className="ms-2 edexaWhiteLogo" />
          </div>
        </div>
      </div>
      <VideoModalComponent videoPlay={videoPlay} setVideoPlay={setVideoPlay} />
    </section>
  );
};

export default BannerSectionComponent;
