import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import { handleLabelKEY } from "../../../common/commonFunctions";
import { getImageUrl } from "../../../common/handleAmazonS3Image";
import { environment } from "../../../environments/environment";
import { IRootReducer } from "../../../store/root-reducer";
import { setCurrentTab } from "../../../store/tab/action";
const SearchIcon = getImageUrl("bstamp-images/serach-icon.svg");
const BstampLogo = getImageUrl("bstamp-images/bstamp-logo.png");

const DashboardTopComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [scrolling, setScrolling] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);

  const isLoggedIn = useSelector((state: IRootReducer) => state.auth.isLoggedIN);
  const selected_Language = useSelector((state: IRootReducer) => state.labelsReducer.lang_);
  const adminlabelsFromReducer = useSelector((state: IRootReducer) => state.labelsReducer.labels);

  const authWithEdexa = () => {
    let url: any = environment.authWithEdexa.login;
    window.location.href = url;
  };

  const handleAccoutClick = () => {
    dispatch(setCurrentTab(2));
    history.push("/account");
  };

  const searchToogle = () => {
    history.push({
      pathname: "/search",
      state: { key: "validate" }
    });
  };

  useEffect(() => {
    var lastScrollTop = 0;
    setScrollTop(0);
    const onScroll = (e: any) => {
      let st = e.target.documentElement.scrollTop;
      if (st === 0) {
        var element = document.getElementById("header-view");
        element?.classList.remove("header-sticky");
      }
      if (st > lastScrollTop) {
        setScrolling(false);
      } else {
        setScrolling(true);
      }
      lastScrollTop = st <= 0 ? 0 : st;
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  return (
    <div id="header-view" className={`btsamp-header ${scrolling ? "header-sticky" : ""}`}>
      <div className="container">
        <div className="header-main d-flex flex-wrap align-items-center justify-content-between">
          <img src={BstampLogo} alt="logo" />
          <div className="buttons d-flex">
            {/* @ts-ignore */}
            <Button color="link" className="hash-button bstamp-white-button text-capitalize" onClick={() => searchToogle()}>
              <img src={SearchIcon} alt="serch icon" className="d-md-none d-block search-icon" />
              <span className="d-md-block d-none">{handleLabelKEY(selected_Language === "English" ? adminlabelsFromReducer?.EN?.validate : adminlabelsFromReducer?.DE?.validate, "Validate")}</span>
            </Button>
            {isLoggedIn ? (
              // @ts-ignore
              <Button color="link" className="login-button bstamp-whiteborder-button text-capitalize ms-2" onClick={() => handleAccoutClick()}>
                My Account
              </Button>
            ) : (
              // @ts-ignore
              <Button color="link" className="login-button bstamp-whiteborder-button text-capitalize ms-2" onClick={() => authWithEdexa()}>
                Login
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardTopComponent;
