import { Dashboard } from "../../Pages/Main/DashboardComponent/Dashboard";
import { Profile } from "../../Pages/Main/Profile";
import { Account } from "../../Pages/Main/accounts";
import { Search } from "../../Pages/Main/search";
import { UnauthorizedUserComponent } from "../../Pages/common/unauthorized";
import UnderMaintenance from "../../Pages/common/undermaintenance";

export const privateRoutes = [
  {
    path: "/",
    component: Dashboard
  },
  {
    path: "/dashboard",
    component: Dashboard
  },
  {
    path: "/account",
    component: Account
  },
  {
    path: "/stamping",
    component: Account
  },
  {
    path: "/signing",
    component: Account
  },
  {
    path: "/pay/cancel",
    component: Account
  },
  {
    path: "/pay/success",
    component: Account
  },

  {
    path: "/search",
    component: Search
  },
  {
    path: "/unauthorized",
    component: UnauthorizedUserComponent
  },
  {
    path: "/:id",
    component: Profile
  }
];

export const publicRoutes = [
  {
    path: "/search",
    component: Search
  },
  {
    path: "/unauthorized",
    component: UnauthorizedUserComponent
  },
  {
    path: "/",
    component: Dashboard
  },
  {
    path: "/:id",
    component: Profile
  },
  {
    path: "/undermaintenance",
    component: UnderMaintenance
  }
];
