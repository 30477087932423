import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import { handleLabelKEY } from "../../../common/commonFunctions";
import { getImageUrl } from "../../../common/handleAmazonS3Image";
import { IRootReducer } from "../../../store/root-reducer";

const ImmutableAudit = getImageUrl("bstamp-images/immutable.png");
const ImmutableAuditTable = getImageUrl("bstamp-images/immutable-table.png");
const Copycode = getImageUrl("bstamp-images/copy-qr.png");
const QrcodeImage = getImageUrl("bstamp-images/qrcode-image.png");
const AccountabilityAudit = getImageUrl("bstamp-images/accountability.png");

const AuditSectionComponent = () => {
  const history = useHistory();

  const selected_Language = useSelector((state: IRootReducer) => state.labelsReducer.lang_);
  const adminlabelsFromReducer = useSelector((state: IRootReducer) => state.labelsReducer.labels);

  const searchToogle = () => {
    history.push({
      pathname: "/search",
      state: { key: "validate" }
    });
  };

  return (
    <section className="bstamp-section audit-section position-relative fixwidth-section">
      <div className="container">
        <div className="row align-items-center position-relative z-index1 mb-xl-5 mb-md-4 mb-3 pb-xl-5 pb-md-4 pb-3">
          <div className="col-lg-5 order-lg-1 order-2">
            <div className="section-title text-lg-start text-center">
              <h2 className="mb-xl-4 mb-3">
                <span className="d-block">
                  {handleLabelKEY(selected_Language === "English" ? adminlabelsFromReducer?.EN?.with_edexa_blockchain : adminlabelsFromReducer?.DE?.with_edexa_blockchain, "With edeXa Blockchain")}
                </span>
              </h2>
              <p className="mb-md-4 mb-3">
                {handleLabelKEY(
                  selected_Language === "English"
                    ? adminlabelsFromReducer?.EN
                        ?.the_digital_verification_process_involves_a_content_integrity_check_so_while_a_timestamp_also_offers_that_benefit_knowing_the_document_hasnt_been_changed_since_the_signature_was_applied_secure_independent_and_portable_proof_of_electronic_record_integrity_by_using_digital_signature_technology_with_edexa_bstamp
                    : adminlabelsFromReducer?.DE
                        ?.the_digital_verification_process_involves_a_content_integrity_check_so_while_a_timestamp_also_offers_that_benefit_knowing_the_document_hasnt_been_changed_since_the_signature_was_applied_secure_independent_and_portable_proof_of_electronic_record_integrity_by_using_digital_signature_technology_with_edexa_bstamp,
                  "the digital verification process involves a content integrity check, so while a timestamp also offers that benefit (knowing the document hasn’t been changed since the signature was applied). secure, independent and portable proof of electronic record integrity. By using digital signature technology with edeXa bstamp."
                )}
              </p>
            </div>
          </div>
          <div className="col-lg-7 order-lg-2 order-1 mb-lg-0 mb-md-4 mb-3">
            <div className="immutable-image-wrapper position-relative">
              <img src={ImmutableAudit} alt="audit" />
              <div className="immutable-table-image">
                <img src={ImmutableAuditTable} alt="table" />
              </div>
            </div>
          </div>
        </div>
        <div className="row align-items-center position-relative z-index1">
          <div className="col-lg-7 mb-lg-0 mb-md-4 mb-3">
            <div className="accountability-image-wrapper position-relative ps-3">
              <img src={AccountabilityAudit} alt="audit" />
              <div className="qrcode-image">
                <img src={QrcodeImage} alt="table" />
              </div>
              <div className="copycode-image">
                <img src={Copycode} alt="copy" />
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="section-title text-lg-start text-center">
              <h2 className="mb-xl-4 mb-3">
                <span className="d-block">
                  {handleLabelKEY(
                    selected_Language === "English" ? adminlabelsFromReducer?.EN?.we_strengthen_your_trust : adminlabelsFromReducer?.DE?.we_strengthen_your_trust,
                    "We strengthen your trust"
                  )}
                </span>
                {handleLabelKEY(selected_Language === "English" ? adminlabelsFromReducer?.EN?.with_blockchain : adminlabelsFromReducer?.DE?.with_blockchain, "with Blockchain.")}
              </h2>
              <p className="mb-md-4 mb-3">
                {handleLabelKEY(
                  selected_Language === "English"
                    ? adminlabelsFromReducer?.EN
                        ?.recipients_of_documents_can_verify_when_the_document_was_electronically_signe_as_well_as_verify_that_the_document_was_not_altered_after_the_date_of_the_timestamp_by_using_edexa_bstamp_organizations_can_protect_their_intellectual_property_and_use_the_timestamps_as_strong_legal_and_auditable_evidence
                    : adminlabelsFromReducer?.DE
                        ?.recipients_of_documents_can_verify_when_the_document_was_electronically_signe_as_well_as_verify_that_the_document_was_not_altered_after_the_date_of_the_timestamp_by_using_edexa_bstamp_organizations_can_protect_their_intellectual_property_and_use_the_timestamps_as_strong_legal_and_auditable_evidence,
                  "Recipients of documents can verify when the document was electronically signed, as well as verify that the document was not altered after the date of the timestamp. By using edeXa bStamp, organizations can protect their intellectual property and use the timestamps as strong, legal and auditable evidence."
                )}
              </p>
              {/* @ts-ignore */}
              <Button color="link" className="learn-more bstamp-blueborder-button text-capitalize" onClick={() => searchToogle()}>
                {handleLabelKEY(selected_Language === "English" ? adminlabelsFromReducer?.EN?.validate : adminlabelsFromReducer?.DE?.validate, "Validate")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AuditSectionComponent;
