import { AuditSectionComponent, AvailableOnComponent, BannerSectionComponent, DashboardFooter, DashboardTopComponent, FrequentlyComponent, HowItWorksComponent, NewsLetterComponent } from ".";
import "../../../assets/scss/layout/bstamp.scss";

export const Dashboard = () => {
  return (
    <>
      <div className={"bstamp-landing"}>
        <DashboardTopComponent />
        <BannerSectionComponent />
        <AvailableOnComponent />
        <AuditSectionComponent />
        <HowItWorksComponent />
        <FrequentlyComponent />
        <NewsLetterComponent />
        <DashboardFooter />
      </div>
    </>
  );
};
