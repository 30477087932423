import { useSelector } from "react-redux";
import { handleLabelKEY } from "../../../common/commonFunctions";
import { getImageUrl } from "../../../common/handleAmazonS3Image";
import { IRootReducer } from "../../../store/root-reducer";

const SignWatermark = getImageUrl("sign-watermark.svg");
const Filehashcon = getImageUrl("bstamp-images/filehash-icon.svg");
const HashblockchainIcon = getImageUrl("bstamp-images/hashblockchain-icon.svg");
const UploadfileIcon = getImageUrl("bstamp-images/uplodfile-icon.svg");

const HowItWorksComponent = () => {
  const selected_Language = useSelector((state: IRootReducer) => state.labelsReducer.lang_);
  const adminlabelsFromReducer = useSelector((state: IRootReducer) => state.labelsReducer.labels);

  return (
    <section className="bstamp-section howwork-section">
      <div className="container">
        <div className="section-title text-center mb-xl-5 mb-md-4 mb-3">
          <h2>{handleLabelKEY(selected_Language === "English" ? adminlabelsFromReducer?.EN?.how_it_works : adminlabelsFromReducer?.DE?.how_it_works, "How It Works")}</h2>
          <p className="fixwidth-description mx-auto">
            {handleLabelKEY(
              selected_Language === "English"
                ? adminlabelsFromReducer?.EN?.how_digitally_signing_your_documents_with_the_edexa_blockchain_works
                : adminlabelsFromReducer?.DE?.how_digitally_signing_your_documents_with_the_edexa_blockchain_works,
              "How digitally signing your documents with the edeXa Blockchain works"
            )}
          </p>
        </div>
        <div className="row">
          <div className="col-md-3 mb-xl-0 mb-md-4 mb-3 hover-box-wrapper">
            <div className="hover-box works-disable-hover text-center">
              <div className="iconbox d-flex flex-wrap align-items-center justify-content-center">
                <img src={UploadfileIcon} alt="UploadfileIcon" className="icon m-auto" />
                <i className="fas fa-angle-double-right howwork-section__doubleArrow" style={{ color: "#DADCE0" }}></i>
              </div>
              <div className="hover-box-title upload">
                {handleLabelKEY(selected_Language === "English" ? adminlabelsFromReducer?.EN?.upload_file : adminlabelsFromReducer?.DE?.upload_file, "Upload File")}
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-xl-0 mb-md-3 mb-3 hover-box-wrapper">
            <div className="hover-box works-disable-hover text-center">
              <div className="iconbox d-flex flex-wrap align-items-center justify-content-center">
                <img src={SignWatermark} alt="SignWatermark" className="icon m-auto" />
                <i className="fas fa-angle-double-right howwork-section__doubleArrow" style={{ color: "#DADCE0" }}></i>
              </div>
              <div className="hover-box-title signWaterMark">
                {handleLabelKEY(selected_Language === "English" ? adminlabelsFromReducer?.EN?.sign_watermark : adminlabelsFromReducer?.DE?.sign_watermark, "Sign watermark")}
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-xl-0 mb-md-3 mb-3 hover-box-wrapper">
            <div className="hover-box works-disable-hover text-center">
              <div className="iconbox d-flex flex-wrap align-items-center justify-content-center">
                <img src={Filehashcon} alt="Filehashcon" className="icon m-auto" />
                <i className="fas fa-angle-double-right howwork-section__doubleArrow" style={{ color: "#DADCE0" }}></i>
              </div>
              <div className="hover-box-title fileHash">
                {handleLabelKEY(selected_Language === "English" ? adminlabelsFromReducer?.EN?.file_hash : adminlabelsFromReducer?.DE?.file_hash, "File Hash")}
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-xl-0 mb-md-3 mb-3 hover-box-wrapper">
            <div className="hover-box works-disable-hover text-center">
              <div className="iconbox d-flex flex-wrap align-items-center justify-content-center">
                <img src={HashblockchainIcon} alt="HashblockchainIcon" className="icon m-auto" />
              </div>
              <div className="hover-box-title" style={{ width: "100%" }}>
                {handleLabelKEY(
                  selected_Language === "English" ? adminlabelsFromReducer?.EN?.blockchain_record_registration : adminlabelsFromReducer?.DE?.blockchain_record_registration,
                  "Blockchain Record Registration"
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorksComponent;
