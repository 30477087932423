import { useState } from "react";
import { useSelector } from "react-redux";
import { Card, CardBody, Collapse } from "reactstrap";
import { handleLabelKEY } from "../../../common/commonFunctions";
import { getImageUrl } from "../../../common/handleAmazonS3Image";
import { IRootReducer } from "../../../store/root-reducer";

const ChevronDown = getImageUrl("bstamp-images/chevron-down.svg");
const ChevronUp = getImageUrl("bstamp-images/chevron-up.svg");

const FrequentlyComponent = () => {
  const selected_Language = useSelector((state: IRootReducer) => state.labelsReducer.lang_);
  const adminlabelsFromReducer = useSelector((state: IRootReducer) => state.labelsReducer.labels);

  const [isToggle, setIsToggele] = useState({
    step1: true,
    step2: false,
    step3: false,
    step4: false,
    step5: false,
    step6: false
  });

  const toggle = (step: number) => {
    let stepData = { ...isToggle };
    Object.keys(isToggle).forEach((key) => {
      if (key.includes(String(step))) {
        // @ts-ignore
        stepData[key] = true;
      } else {
        // @ts-ignore
        stepData[key] = false;
      }
    });
    setIsToggele(stepData);
  };

  return (
    <section className="bstamp-section faq-section fixwidth-section">
      <div className="container">
        <div className="row">
          <div className="col-xl-4 col-lg-5 mb-lg-0 mb-4">
            <div className="section-title text-lg-start text-center sticky-title">
              <h2 className="mb-xl-4 mb-3">
                <span className="d-block">{handleLabelKEY(selected_Language === "English" ? adminlabelsFromReducer?.EN?.frequently : adminlabelsFromReducer?.DE?.frequently, "Frequently")}</span>
                {handleLabelKEY(selected_Language === "English" ? adminlabelsFromReducer?.EN?.asked_questions : adminlabelsFromReducer?.DE?.asked_questions, "Asked Questions")}
              </h2>
              <p>Bring attribution, accountability and auditability to your business processes with edeXa Business Blockchain</p>
            </div>
          </div>
          <div className="col-xl-8 col-lg-7">
            <div className="faq-layout">
              <div className="faq-item">
                <div className={`collapsehead d-flex flex-wrap align-items-center justify-content-between ${isToggle.step1 ? "open" : "close"}`} onClick={() => toggle(1)}>
                  <div className="tittle">
                    <p>
                      <strong>
                        {handleLabelKEY(
                          selected_Language === "English" ? adminlabelsFromReducer?.EN?.what_is_the_bstamp_process : adminlabelsFromReducer?.DE?.what_is_the_bstamp_process,
                          "What is the bStamp process?"
                        )}
                      </strong>
                    </p>
                  </div>
                  <div className="toggle-icon">{isToggle.step1 ? <img src={ChevronUp} alt="ChevronUp" /> : <img src={ChevronDown} alt="ChevronDown" />}</div>
                </div>
                {/* @ts-ignore */}
                <Collapse isOpen={isToggle.step1}>
                  {/* @ts-ignore */}
                  <Card className="border-0 bg-transparent">
                    {/* @ts-ignore */}
                    <CardBody>
                      <p>
                        {handleLabelKEY(
                          selected_Language === "English"
                            ? adminlabelsFromReducer?.EN?.with_our_private_blockchain_network_users_can_bstamp_any_document_or_electronic_file_just_upload_it_and_youre_done
                            : adminlabelsFromReducer?.DE?.with_our_private_blockchain_network_users_can_bstamp_any_document_or_electronic_file_just_upload_it_and_youre_done,
                          "With our private blockchain network, users can bStamp any document or electronic file. Just upload it and you're done."
                        )}
                      </p>
                    </CardBody>
                  </Card>
                </Collapse>
              </div>

              <div className="faq-item">
                <div className={`collapsehead d-flex flex-wrap align-items-center justify-content-between ${isToggle.step2 ? "open" : "close"}`} onClick={() => toggle(2)}>
                  <div className="tittle">
                    <p>
                      <strong>
                        {handleLabelKEY(
                          selected_Language === "English" ? adminlabelsFromReducer?.EN?.does_bstamp_store_my_file : adminlabelsFromReducer?.DE?.does_bstamp_store_my_file,
                          "Does bStamp store my file?"
                        )}
                      </strong>
                    </p>
                  </div>
                  <div className="toggle-icon">{isToggle.step2 ? <img src={ChevronUp} alt="ChevronUp" /> : <img src={ChevronDown} alt="ChevronDown" />}</div>
                </div>
                {/* @ts-ignore */}
                <Collapse isOpen={isToggle.step2}>
                  {/* @ts-ignore */}
                  <Card className="border-0 bg-transparent">
                    {/* @ts-ignore */}
                    <CardBody>
                      <p>
                        {handleLabelKEY(
                          selected_Language === "English"
                            ? adminlabelsFromReducer?.EN?.no_we_do_not_have_any_copies_of_your_file_we_store_only_the_hash_of_the_file_256_sha_and_the_filename_on_the_blockchain
                            : adminlabelsFromReducer?.DE?.no_we_do_not_have_any_copies_of_your_file_we_store_only_the_hash_of_the_file_256_sha_and_the_filename_on_the_blockchain,
                          "No, we do not have any copies of your file. We store only the hash of the file (256 SHA) and the filename on the blockchain."
                        )}
                      </p>
                    </CardBody>
                  </Card>
                </Collapse>
              </div>

              <div className="faq-item">
                <div className={`collapsehead d-flex flex-wrap align-items-center justify-content-between ${isToggle.step3 ? "open" : "close"}`} onClick={() => toggle(3)}>
                  <div className="tittle">
                    <p>
                      <strong>
                        {handleLabelKEY(
                          selected_Language === "English"
                            ? adminlabelsFromReducer?.EN?.how_much_does_it_cost_to_stamp_my_files_with_bstamp
                            : adminlabelsFromReducer?.DE?.how_much_does_it_cost_to_stamp_my_files_with_bstamp,
                          "How much does it cost to stamp my files with bStamp?"
                        )}
                      </strong>
                    </p>
                  </div>
                  <div className="toggle-icon">{isToggle.step3 ? <img src={ChevronUp} alt="ChevronUp" /> : <img src={ChevronDown} alt="ChevronDown" />}</div>
                </div>
                {/* @ts-ignore */}
                <Collapse isOpen={isToggle.step3}>
                  {/* @ts-ignore */}
                  <Card className="border-0 bg-transparent">
                    {/* @ts-ignore */}
                    <CardBody>
                      <p>
                        {handleLabelKEY(
                          selected_Language === "English"
                            ? adminlabelsFromReducer?.EN?.you_will_receive_a_free_onboard_gift_with_the_first_50_files_you_upload_next_after_that_you_will_need_to_pay_according_to_our_pricing_plans
                            : adminlabelsFromReducer?.DE?.you_will_receive_a_free_onboard_gift_with_the_first_50_files_you_upload_next_after_that_you_will_need_to_pay_according_to_our_pricing_plans,
                          "You will receive a free on-board gift with the first50 files you upload Next. After that, you will need to pay according to our pricing plans."
                        )}
                      </p>
                    </CardBody>
                  </Card>
                </Collapse>
              </div>
              <div className="faq-item">
                <div className={`collapsehead d-flex flex-wrap align-items-center justify-content-between ${isToggle.step4 ? "open" : "close"}`} onClick={() => toggle(4)}>
                  <div className="tittle">
                    <p>
                      <strong>
                        {handleLabelKEY(
                          selected_Language === "English"
                            ? adminlabelsFromReducer?.EN?.what_is_a_bstamp_does_watermark_need_to_be_capitalized
                            : adminlabelsFromReducer?.DE?.what_is_a_bstamp_does_watermark_need_to_be_capitalized,
                          "What is a bStamp? Does “Watermark” need to be capitalized?"
                        )}
                      </strong>
                    </p>
                  </div>
                  <div className="toggle-icon">{isToggle.step4 ? <img src={ChevronUp} alt="ChevronUp" /> : <img src={ChevronDown} alt="ChevronDown" />}</div>
                </div>
                {/* @ts-ignore */}
                <Collapse isOpen={isToggle.step4}>
                  {/* @ts-ignore */}
                  <Card className="border-0 bg-transparent">
                    {/* @ts-ignore */}
                    <CardBody>
                      <p>
                        {handleLabelKEY(
                          selected_Language === "English"
                            ? adminlabelsFromReducer?.EN?.an_edexa_certified_watermark_can_be_added_to_the_footer_or_header_of_any_pdf_file_by_using_pdf_watermarking
                            : adminlabelsFromReducer?.DE?.an_edexa_certified_watermark_can_be_added_to_the_footer_or_header_of_any_pdf_file_by_using_pdf_watermarking,
                          "An edeXa certified Watermark can be added to the footer or header of any PDF file by using PDF watermarking."
                        )}
                      </p>
                    </CardBody>
                  </Card>
                </Collapse>
              </div>
              <div className="faq-item">
                <div className={`collapsehead d-flex flex-wrap align-items-center justify-content-between ${isToggle.step5 ? "open" : "close"}`} onClick={() => toggle(5)}>
                  <div className="tittle">
                    <p>
                      <strong>
                        {handleLabelKEY(
                          selected_Language === "English" ? adminlabelsFromReducer?.EN?.what_is_the_json_view_in_browse : adminlabelsFromReducer?.DE?.what_is_the_json_view_in_browse,
                          "What is the JSON View in Browse?"
                        )}
                      </strong>
                    </p>
                  </div>
                  <div className="toggle-icon">{isToggle.step5 ? <img src={ChevronUp} alt="ChevronUp" /> : <img src={ChevronDown} alt="ChevronDown" />}</div>
                </div>
                {/* @ts-ignore */}
                <Collapse isOpen={isToggle.step5}>
                  {/* @ts-ignore */}
                  <Card className="border-0 bg-transparent">
                    {/* @ts-ignore */}
                    <CardBody>
                      <p>
                        {handleLabelKEY(
                          selected_Language === "English"
                            ? adminlabelsFromReducer?.EN?.in_order_to_get_json_data_from_stamped_files_you_need_to_use_json
                            : adminlabelsFromReducer?.DE?.in_order_to_get_json_data_from_stamped_files_you_need_to_use_json,
                          "In order to get JSON data from stamped files, you needto use Json."
                        )}
                      </p>
                    </CardBody>
                  </Card>
                </Collapse>
              </div>
              <div className="faq-item">
                <div className={`collapsehead d-flex flex-wrap align-items-center justify-content-between ${isToggle.step6 ? "open" : "close"}`} onClick={() => toggle(6)}>
                  <div className="tittle">
                    <p>
                      <strong>
                        {handleLabelKEY(
                          selected_Language === "English" ? adminlabelsFromReducer?.EN?.how_to_start_with_bstamp_api : adminlabelsFromReducer?.DE?.how_to_start_with_bstamp_api,
                          "How to start with bStamp API."
                        )}
                      </strong>
                    </p>
                  </div>
                  <div className="toggle-icon">{isToggle.step6 ? <img src={ChevronUp} alt="ChevronUp" /> : <img src={ChevronDown} alt="ChevronDown" />}</div>
                </div>
                {/* @ts-ignore */}
                <Collapse isOpen={isToggle.step6}>
                  {/* @ts-ignore */}
                  <Card className="border-0 bg-transparent">
                    {/* @ts-ignore */}
                    <CardBody>
                      <p>
                        {handleLabelKEY(
                          selected_Language === "English"
                            ? adminlabelsFromReducer?.EN
                                ?.subscribing_to_our_api_will_give_you_the_credentials_such_as_clientid_and_secret_key_please_have_a_look_at_your_complete_document_on_for_the_details
                            : adminlabelsFromReducer?.DE
                                ?.subscribing_to_our_api_will_give_you_the_credentials_such_as_clientid_and_secret_key_please_have_a_look_at_your_complete_document_on_for_the_details,
                          "To access our API and obtain your credentials, please log in to your account on the"
                        )}{" "}
                        <a className="cursor-pointer" href={process.env.REACT_APP_ACCOUNT_URL} target="_blank" rel="noreferrer">
                          {/* {process.env.REACT_APP_ACCOUNT_URL} */}
                          edeXa Account Portal
                        </a>{" "}
                        {handleLabelKEY(
                          selected_Language === "English" ? adminlabelsFromReducer?.EN?.for_the_details : adminlabelsFromReducer?.DE?.for_the_details,
                          " and navigate to the API section. Here you will find detailed instructions on how to subscribe to our API and retrieve your ClientID and Secret Key. If you encounter any issues or have further questions, please don't hesitate to contact our support team for assistance."
                        )}{" "}
                      </p>
                    </CardBody>
                  </Card>
                </Collapse>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FrequentlyComponent;
