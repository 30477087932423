import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, FormGroup, Input } from "reactstrap";
import { onNewsLetter } from "../../../actions/dashboard";
import { handleLabelKEY, toastSuccess } from "../../../common/commonFunctions";
import { emailPattern, validationMessages } from "../../../common/constants";
import { setLoading } from "../../../store/loader/action";
import { IRootReducer } from "../../../store/root-reducer";

const NewsLetterComponent = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState({
    value: "",
    error: ""
  });

  const selected_Language = useSelector((state: IRootReducer) => state.labelsReducer.lang_);
  const adminlabelsFromReducer = useSelector((state: IRootReducer) => state.labelsReducer.labels);

  const handleEmailSubmit = () => {
    if (email.value === "") {
      setEmail({ ...email, error: validationMessages.email.required });
    } else if (!emailPattern.test(email.value)) {
      setEmail({ ...email, error: validationMessages.email.invalid });
    } else {
      dispatch(setLoading(true));
      const postData = {
        email: email.value
      };
      onNewsLetter(postData).then((res: any) => {
        if (res.status === 200) {
          setEmail({
            value: "",
            error: ""
          });
          toastSuccess(res.message);
        }
        dispatch(setLoading(false));
      });
    }
  };

  const handleChangeEmail = (e: any) => {
    setEmail({ ...email, value: e.target.value, error: "" });
  };

  return (
    <section className="bstamp-section newslatter-section py-md-0">
      <div className="container">
        <div className="newslatter-section-main">
          <div className="newslatter-title">
            {handleLabelKEY(selected_Language === "English" ? adminlabelsFromReducer?.EN?.newsletter_be_there_now : adminlabelsFromReducer?.DE?.newsletter_be_there_now, "Newsletter - Be there now")}
          </div>
          <p>
            {handleLabelKEY(
              selected_Language === "English"
                ? adminlabelsFromReducer?.EN?.we_will_keep_you_informed_about_updates_new_services_and_the_development_of_our_blockchain
                : adminlabelsFromReducer?.DE?.we_will_keep_you_informed_about_updates_new_services_and_the_development_of_our_blockchain,
              "We will keep you informed about updates, new services and the development of our blockchain."
            )}
          </p>
          <div className="newslatter-box">
            <div className="d-flex">
              {/* @ts-ignore */}
              <FormGroup>
                {/* @ts-ignore */}
                <Input
                  type="email"
                  name="email"
                  value={email.value}
                  onChange={handleChangeEmail}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      //@ts-ignore
                      document.getElementById("emailSub").click();
                    }
                  }}
                  id="exampleEmail"
                  placeholder="Your e-mail address"
                />
              </FormGroup>
              {/* @ts-ignore */}
              <Button color="link" id="emailSub" className="submit-button bstamp-blue-button text-capitalize m-auto" onClick={handleEmailSubmit}>
                {handleLabelKEY(selected_Language === "English" ? adminlabelsFromReducer?.EN?.subscribe : adminlabelsFromReducer?.DE?.subscribe, "Subscribe")}
              </Button>
            </div>
          </div>
          {email.error !== "" && <div className="px-4 pt-1 text-danger">{email.error}</div>}
        </div>
      </div>
    </section>
  );
};

export default NewsLetterComponent;
