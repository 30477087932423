import { useSelector } from "react-redux";
import { handleLabelKEY } from "../../../common/commonFunctions";
import { getImageUrl } from "../../../common/handleAmazonS3Image";
import IconComponent from "../../../components/dashboard/iconComponent";
import { environment } from "../../../environments/environment";
import { IRootReducer } from "../../../store/root-reducer";
const EdexaLogo = getImageUrl("bstamp-images/edexa-logo.svg");
const EnvelopeIcon = getImageUrl("bstamp-images/envelope.svg");
const FacebookIcon = getImageUrl("bstamp-images/facebook.svg");
const LinkedinIcon = getImageUrl("bstamp-images/linkedin.svg");
const TwitterIcon = getImageUrl("bstamp-images/twitter.svg");

const DashboardFooter = () => {
  const selected_Language = useSelector((state: IRootReducer) => state.labelsReducer.lang_);
  const adminlabelsFromReducer = useSelector((state: IRootReducer) => state.labelsReducer.labels);

  return (
    <section className="bstamp-section bstamp-footer">
      <div className="container-lg">
        <div className="row align-items-center">
          <div className="col-lg-7 col-md-6 mb-md-0 mb-3">
            <div className="links d-flex flex-wrap align-items-center justify-content-md-start justify-content-center">
              <a href="https://edexa.network" target={"_blank"} className="edexa-logo" rel="noreferrer">
                <img src={EdexaLogo} alt="EdexaLogo" />
              </a>
              <div className="social-links d-flex flex-wrap align-items-center">
                <IconComponent href="mailto:helpdesk@edexa.com" name="envelop" icon={EnvelopeIcon} />
                <IconComponent href="https://www.linkedin.com/company/edexablockchain" name="linkedin" icon={LinkedinIcon} />
                <IconComponent href="https://twitter.com/edexablockchain" name="twitter" icon={TwitterIcon} />
                <IconComponent href="https://www.facebook.com/edexablockchain" name="facebook" icon={FacebookIcon} />
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-6 text-md-end text-md-start text-center">
            <div className="pages">
              <a href={environment.tCondition} target="_blank" className="pages-link" rel="noreferrer">
                {handleLabelKEY(selected_Language === "English" ? adminlabelsFromReducer?.EN?.terms_of_service : adminlabelsFromReducer?.DE?.terms_of_service, "Terms of Service")}
              </a>
              <a href={environment.privacy} target="_blank" className="pages-link" rel="noreferrer">
                {handleLabelKEY(selected_Language === "English" ? adminlabelsFromReducer?.EN?.privacy_policy : adminlabelsFromReducer?.DE?.privacy_policy, "Privacy Policy")}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DashboardFooter;
