import { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  handleLabelKEY,
  toastSuccess
} from '../../../common/commonFunctions'
import { getImageUrl } from '../../../common/handleAmazonS3Image'
import DesktopAppModalComponent from '../../../components/dashboard/desktopAppModalComponent'
import { environment } from '../../../environments/environment'
import { IRootReducer } from '../../../store/root-reducer'

const ChromeIcon = getImageUrl('bstamp-images/chrome-icon.svg')
const WebIcon = getImageUrl('bstamp-images/web-icon.svg')
const ApiIcon = getImageUrl('bstamp-images/api-icon.svg')
const WindowIcon = getImageUrl('bstamp-images/window-icon.svg')

const AvailableOnComponent = () => {
  const [open, setOpen] = useState(false)

  const selected_Language = useSelector(
    (state: IRootReducer) => state.labelsReducer.lang_
  )
  const adminlabelsFromReducer = useSelector(
    (state: IRootReducer) => state.labelsReducer.labels
  )

  const redirectPlaform = (type: any) => {
    if (type === 'web') {
      document.documentElement.scrollTop = 0
    } else if (type === 'api') {
      window.open(
        `${environment.appsDomain.portalDomain}/apis`,
        '_blank'
      )
    } else if (type === 'window') {
      window.open(environment.msApplication, '_blank')
    } else if (type === 'linux') {
      toastSuccess('Coming Soon...')
    } else if (type === 'ext') {
      window.open(environment.chromeExtension, '_blank')
    } else if (type === 'mac') {
      toastSuccess('Coming Soon...')
    }
  }
  return (
    <section className="bstamp-section availableon-section pb-xl-4 mb-xl-2">
      <div className="container">
        <div className="section-title text-center mb-xl-5 mb-md-4 mb-3">
          <h2>
            {handleLabelKEY(
              selected_Language === 'English'
                ? adminlabelsFromReducer?.EN?.available_on
                : adminlabelsFromReducer?.DE?.available_on,
              'Available On'
            )}
          </h2>
          <p className="fixwidth-description mx-auto">
            Bring attribution, accountability and auditability to your
            business processes with the edeXa Business Blockchain
          </p>
        </div>
        <div className="row justify-content-between">
          <div className="col-xl-3 col-sm-6 mb-xl-0 mb-md-4 mb-3 hover-box-wrapper">
            <div
              className="hover-box text-center cursor-pointer"
              onClick={() => redirectPlaform('ext')}
            >
              <div className="iconbox d-flex flex-wrap align-items-center justify-content-center">
                <img
                  src={ChromeIcon}
                  alt="ChromeIcons"
                  className="icon m-auto"
                />
              </div>
              <p>
                <span
                  onClick={() => redirectPlaform('ext')}
                  className="box-link"
                >
                  {handleLabelKEY(
                    selected_Language === 'English'
                      ? adminlabelsFromReducer?.EN?.chrome_extension
                      : adminlabelsFromReducer?.DE?.chrome_extension,
                    'Chrome Extension'
                  )}
                </span>
                {handleLabelKEY(
                  selected_Language === 'English'
                    ? adminlabelsFromReducer?.EN
                        ?.to_notarize_page_content_or_screenshots_of_visible_areas_of_active_tabs
                    : adminlabelsFromReducer?.DE
                        ?.to_notarize_page_content_or_screenshots_of_visible_areas_of_active_tabs,
                  'to notarize page content or screenshots of visible areas of active tabs'
                )}
              </p>
              <span
                onClick={() => redirectPlaform('ext')}
                className="view-more-box view-more"
              >
                {handleLabelKEY(
                  selected_Language === 'English'
                    ? adminlabelsFromReducer?.EN?.view_more
                    : adminlabelsFromReducer?.DE?.view_more,
                  'View More'
                )}
              </span>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6 mb-xl-0 mb-md-4 mb-3 hover-box-wrapper">
            <div
              className="hover-box text-center cursor-pointer"
              onClick={() => redirectPlaform('web')}
            >
              <div className="iconbox d-flex flex-wrap align-items-center justify-content-center">
                <img
                  src={WebIcon}
                  alt="WebIcon"
                  className="icon m-auto"
                />
              </div>
              <p>
                <span
                  onClick={() => redirectPlaform('web')}
                  className="box-link"
                >
                  Web
                </span>{' '}
                {handleLabelKEY(
                  selected_Language === 'English'
                    ? adminlabelsFromReducer?.EN
                        ?.to_notarize_page_content_or_screenshots_of_visible_areas_of_active_tabs
                    : adminlabelsFromReducer?.DE
                        ?.to_notarize_page_content_or_screenshots_of_visible_areas_of_active_tabs,
                  'to notarize page content or screenshots of visible areas of active tabs'
                )}
              </p>
              <span
                onClick={() => redirectPlaform('web')}
                className="view-more-box view-more"
              >
                {handleLabelKEY(
                  selected_Language === 'English'
                    ? adminlabelsFromReducer?.EN?.view_more
                    : adminlabelsFromReducer?.DE?.view_more,
                  'View More'
                )}
              </span>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6 mb-xl-0 mb-md-4 mb-3 hover-box-wrapper">
            <div
              className="hover-box text-center cursor-pointer"
              onClick={() => redirectPlaform('api')}
            >
              <div className="iconbox d-flex flex-wrap align-items-center justify-content-center">
                <img
                  src={ApiIcon}
                  alt="ApiIcon"
                  className="icon m-auto"
                />
              </div>
              <p>
                <span
                  onClick={() => redirectPlaform('api')}
                  className="box-link"
                >
                  API
                </span>{' '}
                to notarize page content or screenshots of visible
                areas of active tabs
              </p>
              <span
                onClick={() => redirectPlaform('api')}
                className="view-more-box view-more"
              >
                {handleLabelKEY(
                  selected_Language === 'English'
                    ? adminlabelsFromReducer?.EN?.view_more
                    : adminlabelsFromReducer?.DE?.view_more,
                  'View More'
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
      <DesktopAppModalComponent open={open} setOpen={setOpen} />
    </section>
  )
}

export default AvailableOnComponent
